import React from 'react'
import { Row, Col, Button} from "antd";
import AboutSection from '../../components/AboutSection';

function AboutPage() {
  return (
    <div className='innerpage'>
        <Row justify="center" >
        <Col xs={22} md={20} xl={20}>
            <AboutSection/>
        {/* <h5>About Us</h5>
            <Row justify="center"> 
            <Col  xs={24} md={20}>
            <div className='about-section'> 
            
            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially . It was popularised in the with the release of Letraset sheets. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo</p>
            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially . It was popularised in the with the release of Letraset sheets. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo</p>
        
    </div>
                
            </Col>
            
           
            </Row> */}
           
        </Col>
        </Row>
        </div>
  )
}

export default AboutPage