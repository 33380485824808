import React from "react";
import { Row, Col, Tabs } from "antd";

function PrivacyTerms() {
    const { TabPane } = Tabs;
  return (
    <div className="innerpage">
      <Row justify={"center"}>
        <Col xs={22} md={22}>
        <div className="tabs-container">
      
      <div className="tabs-content">
        <Tabs defaultActiveKey="1" tabPosition="left">
          <TabPane tab="1. Privacy Policy" key="1">
            <h5>Privacy Policy</h5>
            <h3>Welcome to Proscomics.xyz!</h3>
            <p>This Privacy Policy explains how we collect, use, and disclose your information when you use our website (the "Website").</p>
            <h3>1. Information We Collect</h3>
            <p>
            We collect the following types of information from you:</p>
            <p><strong>Personal Information:</strong> This includes information that can be used to identify you, such as your name, email address, postal address, and phone number. We collect this information when you create an account, subscribe to our newsletter, or contact us.</p>
            <p><strong>Usage Data:</strong> This includes information about your activity on the Website, such as the pages you visit, the time you spend on the Website, and the links you click. We collect this information automatically using cookies and other tracking technologies.</p>
            <h3>2. How We Use Your Information</h3>
            <p>We use the information we collect for the following purposes:</p>
            <ul>
              <li>To provide and operate the Website</li>
              <li>To create and maintain your account</li>
              <li>To create and maintain your account</li>
              <li>To send you newsletters and other promotional materials</li>
              <li>To respond to your inquiries and requests</li>
              <li>To analyze how you use the Website</li>
              <li>To improve the Website</li>
            </ul>
            <h3>3. Sharing Your Information</h3>
            <p>We may share your information with third-party service providers who help us operate the Website and provide our services. These service providers are contractually obligated to keep your information confidential and to use it only for the purposes for which we disclose it to them.</p>
            <p>We may also share your information with third-party advertisers who may use it to display targeted advertising to you. We will not share your personal information with any third-party advertisers without your consent.</p>

            <h3>4. Cookies and Other Tracking Technologies</h3>
            <p>We use cookies and other tracking technologies to collect information about your activity on the Website. Cookies are small files that are placed on your computer or device when you visit a website. They store information that can be retrieved by the website later.</p>
            <p>You can block cookies by activating the setting on your browser that allows you to refuse the setting of all or some cookies. However, if you use your browser settings to block all cookies, you may not be able to access all or parts of the Website.</p>
            
            <h3>5. Data Retention</h3>
            <p>We will retain your information for as long as necessary to fulfill the purposes for which it was collected, as described in this Privacy Policy. We will also retain your information as necessary to comply with our legal obligations, resolve disputes, and enforce our agreements.</p>

            <h3>6. Children's Privacy</h3>
            <p>Our Website is not directed to children under the age of 13. We do not knowingly collect personal information from children under the age of 13. If you are a parent or guardian and you believe that your child has provided us with personal information, please contact us. We will take steps to remove the information from our records.</p>

            <h3>7. Your Choices</h3>
            <p>You have the following choices regarding your information:</p>
<ul>
  <li>You can access and update your personal information in your account settings.</li>
  <li>You can unsubscribe from our email list by clicking on the "unsubscribe" link in any email we send you.</li>
  <li>You can choose to refuse cookies by activating the setting on your browser that allows you to refuse the setting of all or some cookies</li>
</ul>
            

            <h3>8. Security</h3>
            <p>We take reasonable steps to protect your information from unauthorized access, disclosure, alteration, or destruction. However, no website or internet transmission is completely secure. We cannot guarantee the security of your information.</p>
            

            <h3>9. Changes to this Privacy Policy</h3>
            <p>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on the Website. You are advised to review this Privacy Policy periodically for any changes.</p>
           

            <h3>10. Contact Us</h3>
            <p>If you have any questions about this Privacy Policy, please contact us</p>


          </TabPane>
          <TabPane tab="2. TERMS & CONDITIONS" key="2">
            <h5>terms & conditions</h5>

            <h3>Welcome to Proscomics.xyz!</h3>
            <p>These Terms and Conditions ("Terms") govern your access and use of the Proscomics.xyz website (the "Website"), including any content, functionality, and services offered on or through the Website. Please read these Terms carefully before using the Website. By accessing or using the Website, you agree to be bound by these Terms. If you disagree with any part of these Terms, you may not access or use the Website.</p>
            

            <h3>1. Acceptance of Terms</h3>
            <p>These Terms constitute a legal agreement between you and ProsComics.xyz ("we," "us," or "our"). By accessing or using the Website, you agree to be bound by these Terms and any applicable laws and regulations. You also agree to be subject to any future amendments or revisions to these Terms that we may make at our sole discretion. Your continued use of the Website after any such amendments or revisions constitutes your acceptance of the revised Terms.</p>
           

            <h3>2. Content Ownership</h3>
            <p>The Website and its content, including but not limited to text, graphics, images, logos, audio, video, and software, are the property of ProsComics.xyz or its licensors and are protected by copyright, trademark, and other intellectual property laws. You may not copy, modify, distribute, transmit, display, perform, reproduce, publish, license, create derivative works from, or exploit in any way any content on the Website without our prior written permission.</p>
            


            <h3>3. User Conduct</h3>
            <p>You agree to use the Website in a lawful and responsible manner. You agree not to use the Website for any purpose that is prohibited by these Terms, or that is unlawful, harmful, or violates the rights of others.</p>
            

            <h3>4. User Accounts</h3>
            <p>You may create an account on the Website to access certain features and functionalities. You are responsible for maintaining the confidentiality of your account information, including your username and password. You are also responsible for all activity that occurs under your account. You agree to notify us immediately of any unauthorized use of your account or any other security breach.</p>
            

            <h3>5. Disclaimers</h3>
            <p>The website and its content are provided "as is" and without warranties of any kind, express or implied. We disclaim all warranties, including, but not limited to, warranties of merchantability, fitness for a particular purpose, non-infringement, and security. We do not warrant that the website will be available, functional, uninterrupted, or error-free, or that the content will be accurate, reliable, or free of viruses or other harmful components.</p>
            

            <h3>6. Limitation of Liability</h3>
            <p>To the maximum extent permitted by law, we will not be liable for any damages arising out of or related to your use of the website, including, but not limited to, direct, indirect, incidental, consequential, special, punitive, or exemplary damages, even if we have been advised of the possibility of such damages.</p>
            

            <h3>7. Indemnification</h3>
            <p>You agree to indemnify, defend, and hold harmless ProsComics.xyz, its officers, directors, employees, agents, and licensors from and against any and all claims, losses, damages, liabilities, costs, and expenses (including attorneys' fees) arising out of or related to your use of the Website or your violation of these Terms.</p>
            

            <h3>8. Termination</h3>
            <p>We may terminate your access to the Website at any time, for any reason, or no reason at all, without notice or liability. We may also block your access to certain features or functionalities of the Website.</p>
           

            <h3>9. Governing Law</h3>
            <p>These Terms shall be governed by and construed in accordance with the laws of the State of New York, without regard to its conflict of laws principles</p>
           

            <h3>10. Entire Agreement</h3>
            <p>These Terms constitute the entire agreement between you and Proscomics.xyz regarding your use of the Website.</p>
            

            <h3>11. Contact Us</h3>
            <p>If you have any questions about these Terms, please contact us</p>
            
          </TabPane>
        </Tabs>
      </div>
    </div>
        </Col>
      </Row>
    </div>
  );
}

export default PrivacyTerms;
