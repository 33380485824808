import { PayPalButtons, PayPalScriptProvider, usePayPalScriptReducer, } from "@paypal/react-paypal-js";
import React, { memo, useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import swal from "sweetalert";
import { useGetClientIdQuery } from '../../api/cart';
import { Post } from "../../config/api/post";
import { BASE_URL } from '../../config/constants/api';
import { Button, Spin } from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import styles from  './paypal.module.css'

function Message({ content }) {
    return <p>{content}</p>;
}

function PaypalCard({ totalPrice, localOrderData, clientId }) {
    const { NODE_ENV } = process.env;
    const { hostname } = window.location;
    const [{ isPending, isInitial, isRejected, isResolved }] = usePayPalScriptReducer();
    console.log("🚀 ~ PaypalCard ~ isResolved:", isResolved)
    console.log("🚀 ~ PaypalCard ~ isRejected:", isRejected)
    console.log("🚀 ~ PaypalCard ~ isPending:", isPending)
    console.log("🚀 ~ PaypalCard ~ isInitial:", isInitial)
    const token = useSelector((state) => state.user.userToken);
    const { data, refetch } = useGetClientIdQuery();
    // const clientId = data?.data?.clientId;

    // inser script in dom to load paypal sdk
    // useEffect(() => {
    //     const script = document.createElement("script")
    //     script.src = `https://www.paypal.com/sdk/js?client-id=${clientId}`
    //     script.async = true
    //     script.addEventListener("load", () => {
    //         console.log("script loaded")
    //         document.body.appendChild(script)
    //     })

    //     return () => {
    //         if (script) {
    //             // document.body.removeChild(script)
    //         }
    //     }
    // }, [data, token])

    useEffect(() => {
        refetch()
    }, [])
    
    const CompleteOrder = async (paypalOrderId) => {
        try {
            Post(`/order/${localOrderData?._id}/pay`, {paypalOrderId}, token)
                .then((response) => {
                    if (response?.success) {
                        navigate("/payment-success", {state: {data: response?.order}})
                    }
                })
                .catch((err) => {
                    let message = err?.response?.data?.message || err?.message;
                    console.log(":::;", err);
                    if (message) swal("Oops!", message, "error");
                });
        } catch (error) {
            console.error(error);
            setMessage(
                `Sorry, your transaction could not be processed...${error}`
            );
        }
    }

    const [message, setMessage] = useState("");
    const navigate = useNavigate();

    const clickPayButton = () => {
        window.location.reload();
      }

      useEffect(()=>{
        if (isRejected) {
            // clickPayButton()
            console.log("🚀 ~ PaypalCard ~ Paypal Button load Rejected")
            console.log("🚀 ~ PaypalCard ~ clientId:", clientId)
        }
      },[isRejected])

    return (
        <div>
            {/* <PayPalScriptProvider options={{
                "client-id": clientId,
                environment: NODE_ENV === "production" && hostname.includes("realmoneydragon.io") ? "production" : "sandbox",
                // "enable-funding": "venmo",
                // "disable-funding": "",
                // country: "US",
                // currency: "USD",
                // "data-page-type": "product-details",
                // components: "buttons",
                // "data-sdk-integration-source": "developer-studio",
            }}> */}
                {/* <Checkout /> */}
                {!isPending && isRejected && <div style={{display: 'flex', justifyContent: 'center', width: '45%'}}><Button onClick={clickPayButton} className={styles.paybtn}>Pay</Button></div>}
                {isPending ? 
                 <div style={{height: 210, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
                 </div>
                 : <div>
                    <PayPalButtons
                        forceReRender={[totalPrice, localOrderData, clientId]}
                        style={{
                            shape: "rect",
                            layout: "vertical",
                            color: "gold",
                            label: "paypal",
                        }}
                        // createOrder={(data, actions) => {
                        //     return actions.order.create({
                        //         purchase_units: [
                        //             {
                        //                 amount: {
                        //                     value: "10.00", // Replace with the dynamic value
                        //                 },
                        //             },
                        //         ],
                        //     });
                        // }}
                        // onApprove={(data, actions) => {
                        //     return actions.order.capture().then((details) => {
                        //         navigate("/payment-success")
                        //         // alert(`Transaction completed by ${details.payer.name.given_name}`);
                        //         // You can handle post-payment logic here, like updating the order status
                        //     });
                        // }}
                        createOrder={async () => {
                            try {
                                const response = await fetch(`${BASE_URL}/orders`, {
                                    method: "POST",
                                    headers: {
                                        "Content-Type": "application/json",
                                        "Authorization": `Bearer ${token}`
                                    },
                                    // use the "body" param to optionally pass additional order information
                                    // like product ids and quantities
                                    body: JSON.stringify({
                                        cart: [
                                            {
                                                id: "YOUR_PRODUCT_ID",
                                                // quantity: `${parseInt(totalPrice)}`,
                                                quantity: parseInt(totalPrice).toString(),
                                                // totalPrice: totalPrice
                                            },
                                        ],
                                    }),
                                });

                                const orderData = await response.json();
                                if (orderData.id) {
                                    return orderData.id;
                                } else {
                                    const errorDetail = orderData?.details?.[0];
                                    const errorMessage = errorDetail
                                        ? `${errorDetail.issue} ${errorDetail.description} (${orderData.debug_id})`
                                        : JSON.stringify(orderData);

                                    throw new Error(errorMessage);
                                }
                            } catch (error) {
                                console.error(error);
                                setMessage(
                                    `Could not initiate PayPal Checkout...${error}`
                                );
                            }
                        }}
                        onApprove={async (data, actions) => {
                            try {
                                const response = await fetch(
                                    `${BASE_URL}/orders/${data.orderID}/capture`,
                                    {
                                        method: "POST",
                                        headers: {
                                            "Content-Type": "application/json",
                                            "Authorization": `Bearer ${token}`
                                        },
                                    }
                                );


                                const orderData = await response.json();
                                // Three cases to handle:
                                //   (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
                                //   (2) Other non-recoverable errors -> Show a failure message
                                //   (3) Successful transaction -> Show confirmation or thank you message

                                const errorDetail = orderData?.details?.[0];

                                if (errorDetail?.issue === "INSTRUMENT_DECLINED") {
                                    // (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
                                    // recoverable state, per https://developer.paypal.com/docs/checkout/standard/customize/handle-funding-failures/
                                    return actions.restart();
                                } else if (errorDetail) {
                                    // (2) Other non-recoverable errors -> Show a failure message
                                    throw new Error(
                                        `${errorDetail.description} (${orderData.debug_id})`
                                    );
                                } else {
                                    // (3) Successful transaction -> Show confirmation or thank you message
                                    // Or go to another URL:  actions.redirect('thank_you.html');
                                    const transaction =
                                        orderData.purchase_units[0].payments
                                            .captures[0];
                                    setMessage(
                                        `Transaction ${transaction.status}: ${transaction.id}. See console for all available details`
                                    );
                                    console.log(
                                        "Capture result",
                                        orderData,
                                        JSON.stringify(orderData, null, 2)
                                    );
                                    CompleteOrder(orderData?.id)
                                }
                            } catch (error) {
                                console.error(error);
                                setMessage(
                                    `Sorry, your transaction could not be processed...${error}`
                                );
                            }
                        }}
                        onError={(err) => {
                            console.error('PayPal Checkout Error:', err);
                        }}
                    />
                </div>}
            {/* </PayPalScriptProvider> */}
            <Message content={message} />
        </div>

    );
}

export default memo(PaypalCard);

const Checkout = () => {
    const token = useSelector((state) => state.user.userToken);
    const [message, setMessage] = useState("");
    const navigate = useNavigate();
    return (
        <div>
            <h1>Checkout</h1>
            <PayPalButtons
                style={{
                    shape: "rect",
                    layout: "vertical",
                    color: "gold",
                    label: "paypal",
                }}
                // createOrder={(data, actions) => {
                //     return actions.order.create({
                //         purchase_units: [
                //             {
                //                 amount: {
                //                     value: "10.00", // Replace with the dynamic value
                //                 },
                //             },
                //         ],
                //     });
                // }}
                // onApprove={(data, actions) => {
                //     return actions.order.capture().then((details) => {
                //         navigate("/payment-success")
                //         // alert(`Transaction completed by ${details.payer.name.given_name}`);
                //         // You can handle post-payment logic here, like updating the order status
                //     });
                // }}
                createOrder={async () => {
                    try {
                        const response = await fetch(`${BASE_URL}/orders`, {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                                "Authorization": `Bearer ${token}`
                            },
                            // use the "body" param to optionally pass additional order information
                            // like product ids and quantities
                            body: JSON.stringify({
                                cart: [
                                    {
                                        id: "YOUR_PRODUCT_ID",
                                        quantity: "YOUR_PRODUCT_QUANTITY",
                                    },
                                ],
                            }),
                        });

                        const orderData = await response.json();
                        if (orderData.id) {
                            return orderData.id;
                        } else {
                            const errorDetail = orderData?.details?.[0];
                            const errorMessage = errorDetail
                                ? `${errorDetail.issue} ${errorDetail.description} (${orderData.debug_id})`
                                : JSON.stringify(orderData);

                            throw new Error(errorMessage);
                        }
                    } catch (error) {
                        console.error(error);
                        setMessage(
                            `Could not initiate PayPal Checkout...${error}`
                        );
                    }
                }}
                onApprove={async (data, actions) => {
                    try {
                        const response = await fetch(
                            `${BASE_URL}/orders/${data.orderID}/capture`,
                            {
                                method: "POST",
                                headers: {
                                    "Content-Type": "application/json",
                                    "Authorization": `Bearer ${token}`
                                },
                            }
                        );


                        const orderData = await response.json();
                        // Three cases to handle:
                        //   (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
                        //   (2) Other non-recoverable errors -> Show a failure message
                        //   (3) Successful transaction -> Show confirmation or thank you message

                        const errorDetail = orderData?.details?.[0];

                        if (errorDetail?.issue === "INSTRUMENT_DECLINED") {
                            // (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
                            // recoverable state, per https://developer.paypal.com/docs/checkout/standard/customize/handle-funding-failures/
                            return actions.restart();
                        } else if (errorDetail) {
                            // (2) Other non-recoverable errors -> Show a failure message
                            throw new Error(
                                `${errorDetail.description} (${orderData.debug_id})`
                            );
                        } else {
                            // (3) Successful transaction -> Show confirmation or thank you message
                            // Or go to another URL:  actions.redirect('thank_you.html');
                            const transaction =
                                orderData.purchase_units[0].payments
                                    .captures[0];
                            setMessage(
                                `Transaction ${transaction.status}: ${transaction.id}. See console for all available details`
                            );
                            console.log(
                                "Capture result",
                                orderData,
                                JSON.stringify(orderData, null, 2)
                            );
                            navigate("/payment-success")
                        }
                    } catch (error) {
                        console.error(error);
                        setMessage(
                            `Sorry, your transaction could not be processed...${error}`
                        );
                    }
                }}
                onError={(err) => {
                    console.error('PayPal Checkout Error:', err);
                }}
            />
        </div>
    );
};
