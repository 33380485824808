import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ClientLayout from "../../components/layout";
import CheckOut from "../../views/checkout";
import ForgetPasswordCode from "../../views/fogetpasswordcode";
import ForgetPassword from "../../views/forgetpassword";
import Home from "../../views/home";
import Login from "../../views/login";
import MyCart from "../../views/myCart";
import PasswordRecovery from "../../views/passwordrecovery";
import PaymentDetails from "../../views/paymentDetails";
import PaymentSuccess from "../../views/paymentSuccess";
import ProductDetails from "../../views/productDetails";
import ProtectedRoute from "../../views/protectedRoute";
import Shop from "../../views/shop";
import Signup from "../../views/signup";
import RedeemCoins from "../../views/redeem";
import Coins from '../../views/coins';
import GiveaWay from '../../views/giveAways';
import WinnerList from '../../views/winnerList';
import TicketPackages from '../../views/ticket-packages';
import PodCast from '../../views/podcast';
import Community from '../../views/community';
import AboutPage from '../../views/about';
import PrivacyTerms from '../../views/policy';
import ContactUs from '../../views/contactUs';
import ConvertCoins from "../../views/convertCoins";
import RequestCoins from "../../views/requestCoins";


function MyRouter() {

  return (
    <>
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <Routes>
          <Route
            path="/"
            index
            element={
              <ClientLayout header={true}>
                <Home />
              </ClientLayout>
            }
          />
          <Route
            path="/shop"
            index
            element={
              <ClientLayout header={true}>
                <Shop />
              </ClientLayout>
            }
          />
          <Route
            path="/redeem-red-coins"
            index
            element={
              <ClientLayout header={true}>
                <RedeemCoins />
              </ClientLayout>
            }
          />
          <Route
            path="/redeem-gold-coins"
            index
            element={
              <ClientLayout header={true}>
                <RedeemCoins />
              </ClientLayout>
            }
          />
          <Route
            path="/convert-coins"
            index
            element={
              <ClientLayout header={true}>
                <ConvertCoins />
              </ClientLayout>
            }
          />
          <Route
            path="/request-coins"
            index
            element={
              <ClientLayout header={true}>
                <RequestCoins />
              </ClientLayout>
            }
          />
          <Route
            path="/product-details/:id"
            index
            element={
              <ClientLayout header={true}>
                <ProductDetails />
              </ClientLayout>
            }
          />
          <Route
            path="/payment-success"
            index
            element={
              <ClientLayout header={true}>
                <PaymentSuccess />
              </ClientLayout>
            }
          />
          <Route
            path="/cart"
            index
            element={
              <ProtectedRoute>
                <ClientLayout header={true}>
                  <MyCart />
                </ClientLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/checkout"
            index
            element={
              <ProtectedRoute>
                <ClientLayout header={true}>
                  <CheckOut />
                </ClientLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/payment-details/:orderId"
            index
            // lazy={true}
            element={
              <ClientLayout header={true}>
                <PaymentDetails />
              </ClientLayout>
            }
          />
          <Route
            path="/login"
            index
            element={
              <ClientLayout header={true}>
                <Login />
              </ClientLayout>
            }
          />
          <Route
            path="/Signup"
            index
            element={
              <ClientLayout header={true}>
                <Signup />
              </ClientLayout>
            }
          />
          <Route
            path="/forgetpassword"
            index
            element={
              <ClientLayout header={true}>
                <ForgetPassword />
              </ClientLayout>
            }
          />
          <Route
            path="/forgetpasswordcode"
            index
            element={
              <ClientLayout header={true}>
                <ForgetPasswordCode />
              </ClientLayout>
            }
          />
          <Route
            path="/passwordrecovery"
            index
            element={
              <ClientLayout header={true}>
                <PasswordRecovery />
              </ClientLayout>
            }
          />
        <Route path="/my-buxx-coins" index element={<ClientLayout header={true}><Coins /></ClientLayout>} />
        <Route path="/give-away" index element={<ClientLayout header={true}><GiveaWay /></ClientLayout>} />
        <Route path="/winner-list" index element={<ClientLayout header={true}><WinnerList /></ClientLayout>} />
        <Route path="/ticket-packages" index element={<ClientLayout header={true}><TicketPackages /></ClientLayout>} />
        <Route path="/podcast" index element={<ClientLayout header={true}><PodCast /></ClientLayout>} />
        <Route path="/community" index element={<ClientLayout header={true}><Community /></ClientLayout>} />
        <Route path="/about-us" index element={<ClientLayout header={true}><AboutPage /></ClientLayout>} />
        <Route path="/privacy-terms" index element={<ClientLayout header={true}><PrivacyTerms /></ClientLayout>} />
        <Route path="/contact-us" index element={<ClientLayout header={true}><ContactUs /></ClientLayout>} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default MyRouter;
