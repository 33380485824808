import React from "react";
import { Row, Col, Image, Avatar, Input, Button, Dropdown, Space } from "antd";
import { AiOutlineLike } from "react-icons/ai";
import { FaRegCommentDots,FaEdit, FaPlus } from "react-icons/fa";
import { ImShare2 } from "react-icons/im";
import { MdDeleteForever } from "react-icons/md";
import { PiDotsThreeOutlineFill } from "react-icons/pi";
import { ImageUrl } from "../../config/helper";
import { BACKGROUND_VIDEO } from "../../config/constants/api";

function Post() {
  const items = [
    {
      key: "1",
      label: (
        <a
        href="#"
      >
        <MdDeleteForever fontSize={20}  style={{ marginRight:"10px" }} />
          Delete Post
        </a>
      ),
    },
    {
        key: "2",
        label: (
          <a
            href="#"
          >
            <ImShare2 fontSize={20} style={{ marginRight:"10px" }} />
              <span>Share</span>
          </a>
        ),
      },
      {
        key: "3",
        label: (
          <a
            href="#"
          >
            <FaEdit fontSize={20}  style={{ marginRight:"10px" }} />
              <span>Edit</span>
          </a>
        ),
      },
  ];
  return (
    <div>
        
      <Row
        justify="center"
        gutter={30}
        className="greybg"
      >
        <Col xs={24}>
          <Row gutter={20} className="my-15">
            <Col xs={5} md={4} lg={2} xl={2}>
              <Avatar
                size={48}
                icon={<Image preview={false} src={ImageUrl("avatar-profile.png")} />}
              />
            </Col>
            <Col xs={17} md={18} xl={20}>
              <h5 className="m-0">ADMINRM</h5>
              <p>Posted at 2h ago </p>
            </Col>
            <Col xs={2} md={2} xl={2}>
              <Dropdown
                menu={{
                  items,
                }}
              >
                <a onClick={(e) => e.preventDefault()}>
                  <PiDotsThreeOutlineFill />
                </a>
              </Dropdown>
            </Col>
          </Row>
        </Col>
        <Col xs={24}>
          <p>
            Ulus Mus. Nam Fermentum, Nulla Luctus Pharetra Vulputate, Felis
            Tellus Mollis Orci,
          </p>

          <div className="postimage">
            <Row gutter={20}>
                <Col xs={24} md={16} lg={16}>
                {/* <Image
              preview={false}
              src={ImageUrl("post-banner.png")}
              width={"100%"}
            /> */}
            <video controls >
          <source src={BACKGROUND_VIDEO} type="video/mp4" />
        </video>
                </Col>
                <Col xs={24} md={8} lg={8}>
                    <Row>
                        <Col xs={24}>
                        <Image
              preview={false}
              src={ImageUrl("post-banner.png")}
              width={"100%"}
            />
                        </Col>
                        <Col xs={24}>
                            <div className="more-post">
                                <div className="more-post-inner">
                                <FaPlus /> 10
                                </div>
                            <Image
              preview={false}
              src={ImageUrl("post-banner.png")}
              width={"100%"}
            />

                            </div>
                        
                        </Col>
                    </Row>
                
           
                </Col>
            </Row>
           
            <Row justify={"center"}>
              <Col xs={23}>
                <Row justify={"space-around"}>
                  <Col
                    xs={12}
                    md={12}
                    xl={12}
                    style={{ justifyContent: "start", display: "flex" }}
                  >
                    <span className="gradient">
                      <AiOutlineLike fontSize={24} />
                      <span>36 Likes</span>
                    </span>
                  </Col>
                  <Col
                    xs={12}
                    md={12}
                    xl={12}
                    style={{ justifyContent: "end", display: "flex" }}
                  >
                    <span className="gradient">
                      <FaRegCommentDots fontSize={24} />
                      <span>256 Comments</span>
                    </span>
                  </Col>
                </Row>
              </Col>
            </Row>
           
          </div>

          {/* <Row className="sharingarea">
            <Col xs={8} md={6} lg={3} xl={3}>
              <AiOutlineLike fontSize={20} />
              <span>36 Likes</span>
            </Col>
            <Col xs={10} md={6} lg={5} xl={4}>
              <FaRegCommentDots fontSize={20} />
              <span>256 Comments</span>
            </Col>
            <Col xs={6} md={6} lg={3} xl={3}>
              <ImShare2 fontSize={20} />
              <span>Share</span>
            </Col>
          </Row> */}

          <Row className="my-25" gutter={20}>
            <Col xs={5} md={4} lg={2} xl={1}>
              <Avatar
                size={32}
                icon={<Image preview={false} src={ImageUrl("avatar-profile.png")} />}
              />
            </Col>
            <Col xs={19} md={20} lg={22} xl={23}>
              <p>
                Ulus Mus. Nam Fermentum, Nulla Luctus Pharetra Vulputate, Felis
                Tellus Mollis Orci,
              </p>
            </Col>
          </Row>

          <Row className="my-25" gutter={20}>
            <Col xs={5} md={4} lg={2} xl={1}>
             
              <Avatar
                size={32}
                icon={<Image preview={false} src={ImageUrl("avatar-profile.png")} />}
              />
            </Col>
            <Col xs={19} md={20} lg={22} xl={23}>
              <Input placeholder="Abc" />
            </Col>
          </Row>
          <Row justify={"end"}>
            <Col>
              <Button className="mainbtn">Post</Button>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row
        justify="center"
        gutter={30}
        className="greybg"
      >
        <Col xs={24}>
          <Row gutter={20} className="my-15">
            <Col xs={5} md={4} lg={2} xl={2}>
              <Avatar
                size={48}
                icon={<Image preview={false} src={ImageUrl("avatar-profile.png")} />}
              />
            </Col>
            <Col xs={17} md={18} xl={20}>
              <h5 className="m-0">ADMINRM</h5>
              <p>Posted at 2h ago </p>
            </Col>
            <Col xs={2} md={2} xl={2}>
              <Dropdown
                menu={{
                  items,
                }}
              >
                <a onClick={(e) => e.preventDefault()}>
                  <PiDotsThreeOutlineFill />
                </a>
              </Dropdown>
            </Col>
          </Row>
        </Col>
        <Col xs={24}>
          <p>
            Ulus Mus. Nam Fermentum, Nulla Luctus Pharetra Vulputate, Felis
            Tellus Mollis Orci,
          </p>

          <div className="postimage">
            <Row gutter={20}>
                <Col xs={24} md={16} lg={16}>
                <Image
              preview={false}
              src={ImageUrl("post-banner.png")}
              width={"100%"}
            />
            {/* <video controls >
          <source src={BACKGROUND_VIDEO} type="video/mp4" />
        </video> */}
                </Col>
                <Col xs={24} md={8} lg={8}>
                    <Row>
                        <Col xs={24}>
                        <Image
              preview={false}
              src={ImageUrl("post-banner.png")}
              width={"100%"}
            />
                        </Col>
                        <Col xs={24}>
                            <div className="more-post">
                                <div className="more-post-inner">
                                <FaPlus /> 10
                                </div>
                            <Image
              preview={false}
              src={ImageUrl("post-banner.png")}
              width={"100%"}
            />

                            </div>
                        
                        </Col>
                    </Row>
                
           
                </Col>
            </Row>
           
            <Row justify={"center"}>
              <Col xs={23}>
                <Row justify={"space-around"}>
                  <Col
                    xs={12}
                    md={12}
                    xl={12}
                    style={{ justifyContent: "start", display: "flex" }}
                  >
                    <span className="gradient">
                      <AiOutlineLike fontSize={24} />
                      <span>36 Likes</span>
                    </span>
                  </Col>
                  <Col
                    xs={12}
                    md={12}
                    xl={12}
                    style={{ justifyContent: "end", display: "flex" }}
                  >
                    <span className="gradient">
                      <FaRegCommentDots fontSize={24} />
                      <span>256 Comments</span>
                    </span>
                  </Col>
                </Row>
              </Col>
            </Row>
           
          </div>

          {/* <Row className="sharingarea">
            <Col xs={8} md={6} lg={3} xl={3}>
              <AiOutlineLike fontSize={20} />
              <span>36 Likes</span>
            </Col>
            <Col xs={10} md={6} lg={5} xl={4}>
              <FaRegCommentDots fontSize={20} />
              <span>256 Comments</span>
            </Col>
            <Col xs={6} md={6} lg={3} xl={3}>
              <ImShare2 fontSize={20} />
              <span>Share</span>
            </Col>
          </Row> */}

          <Row className="my-25" gutter={20}>
            <Col xs={5} md={4} lg={2} xl={1}>
              <Avatar
                size={32}
                icon={<Image preview={false} src={ImageUrl("avatar-profile.png")} />}
              />
            </Col>
            <Col xs={19} md={20} lg={22} xl={23}>
              <p>
                Ulus Mus. Nam Fermentum, Nulla Luctus Pharetra Vulputate, Felis
                Tellus Mollis Orci,
              </p>
            </Col>
          </Row>

          <Row className="my-25" gutter={20}>
            <Col xs={5} md={4} lg={2} xl={1}>
             
              <Avatar
                size={32}
                icon={<Image preview={false} src={ImageUrl("avatar-profile.png")} />}
              />
            </Col>
            <Col xs={19} md={20} lg={22} xl={23}>
              <Input placeholder="Abc" />
            </Col>
          </Row>
          <Row justify={"end"}>
            <Col>
              <Button className="mainbtn">Post</Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default Post;
