import React, { useState } from "react";
import { Row, Col, Image } from "antd";
import { ImageUrl } from "../../config/helper";
import GiveAwayCard from "../../components/giveAwayCard";

function GiveaWay() {
    const [giveAway, SetGiveAway] = useState([
        {
          key: 1,
          image: "purple-giveaway.png",
          heading: "Dreamy Imaginations",
          coins:"20",
          time:"08"
        },
    
        {
          key: 2,
          image: "blue-giveaway.png",
          heading: "Dreamy Imaginations",
          coins:"20",
          time:"08"
        },
        {
          key: 3,
          image: "red-giveaway.png",
          heading: "Dreamy Imaginations",
          coins:"20",
          time:"08"
        },
        {
            key: 4,
            image: "blue-giveaway.png",
            heading: "Dreamy Imaginations",
            coins:"20",
            time:"08"
          },
        {
            key: 5,
            image: "purple-giveaway.png",
            heading: "Dreamy Imaginations",
            coins:"20",
            time:"08"
          },
      
          
          {
            key:6,
            image: "red-giveaway.png",
            heading: "Dreamy Imaginations",
            coins:"20",
            time:"08"
          },
          {
            key:7,
            image: "red-giveaway.png",
            heading: "Dreamy Imaginations",
            coins:"20",
            time:"08"
          },
          {
            key: 8,
            image: "blue-giveaway.png",
            heading: "Dreamy Imaginations",
            coins:"20",
            time:"08"
          },
        {
            key: 9,
            image: "purple-giveaway.png",
            heading: "Dreamy Imaginations",
            coins:"20",
            time:"08"
          },
      ]);
  return (
    <div>
        <div className="position-relative">
        <Image  preview={false}  src={ImageUrl("dragon-bg.png")} className="bg-vid"  />
      </div>
      <div className="innerpage">
            <Row justify={"center"}>
                <Col xs={22} md={20} className="text-center">
                <Image  preview={false}  src={ImageUrl("giveawaybanner.png")} alt="" />
                </Col>
                <Col  xs={22} md={20} >
                    <h5 className="mt-50">CURRENT CONTEST</h5>
                </Col>
                <Col  xs={22} md={20} >
                    <Row gutter={40} justify={"center"}>
                    {giveAway.length > 0 &&
                giveAway.map((item) => {
                  return (
                    <Col xs={22} md={12} lg={8}>
                      <GiveAwayCard item={item}/>
                    </Col>
                  );
                })}
                    </Row>
                </Col>
               
            </Row>
      </div>
    </div>
  )
}

export default GiveaWay

