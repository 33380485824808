import React from 'react'
import { Image, Button} from "antd";
import { ImageUrl } from "../../config/helper";

function GiveAwayCard({item}) {
  return (
    <div className='give-away-card'>
        <div className='giveaway-top-area'>
            <div className='ribbon'>Draw Today AT  {item.time} AM</div>
             <Image  preview={false}  src={ImageUrl(item.image)} alt="" />
        </div>
        <h4>{item.heading}</h4>
        <h5><Image  preview={false}  src={ImageUrl("batch-icon.png")}  />Price Worth - {item.coins} Credit Coins</h5>
        <Button className='mainbtn'>ENTER IN THIS GIVEAWAY</Button>
    </div>
  )
}

export default GiveAwayCard