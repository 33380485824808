import React, {useState } from "react";
// import AuthLayout from "../../components/";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  Layout,
  Row,
  Typography,
  Image,
  Modal
} from "antd";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { ImageUrl } from "../../config/helper";
import { GoKey } from "react-icons/go";
import { AUTH, BACKGROUND_VIDEO, UPLOADS_URL } from "../../config/constants/api";
import { Background } from "../home";
import swal from "sweetalert";
import { Post } from "../../config/api/post";

function PasswordRecovery() {
    const {state} = useLocation();
    console.log("🚀 ~ PasswordRecovery ~ state:", state)
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
      setIsModalOpen(true);
    };
    const handleOk = () => {
      setIsModalOpen(false);
    };
    const handleCancel = () => {
      setIsModalOpen(false);
    };

    const onFinish = async (values) => {
      if (values.password !== values.confirm_password) {
        swal("Error", "Password and Confirm Password should be same", "error");
        return;  
      }
      setLoading(true);
      await Post(AUTH.resetPassword, { code: state.code, email: state.email, password: values.password })
      .then((response) => {
        setLoading(false);

        if (response?.status) {
          swal("Success", response?.message, "success");

          navigate("/login", {
            replace: true,
          });
        } else {
          setLoading(false);
          swal(
            "Oops!",
            response?.data?.message || response?.response?.data?.message,
            "error"
          );
        }
      })

      .catch((e) => {
        setLoading(false);
        swal("Oops!", e?.response?.data?.message || e?.message, "error");
      });
    };

  return (
    <>
      <Background />
      <Layout className="banner">
        <Row className="flex">
          <Col xs={22} md={12} lg={8}>
            <Card>
              <div className="authFormBox checkout-form">
                <Row style={{ width: "100%", justifyContent: "center" }}>
                  <Col xs={20} md={20} className="formWrap">
                    <h3>FORGOT PASSWORD</h3>
                    <p>Set new password for your account</p>

                    <br />
                    <Form
                      layout="vertical"
                      name="basic"
                      className="loginForm"
                      labelCol={{
                        span: 0,
                      }}
                      wrapperCol={{
                        span: 24,
                      }}
                      initialValues={{
                        remember: true,
                      }}
                      onFinish={onFinish}
                      // onFinishFailed={onFinishFailed}
                      autoComplete="off"
                    >
                        <Form.Item
                        label="Password"
                        name="password"
                        rules={[
                          {
                            required: true,
                            message: "Please input your password!",
                          },
                        ]}
                      >
                        <Input.Password
                          size="large"
                          placeholder="Enter Password"
                          className="AuthFormInput"
                          prefix={<GoKey />}
                        />
                      </Form.Item>
                      

                      <Form.Item
                        label="Confirm Password"
                        name="confirm_password"
                        rules={[
                          {
                            required: true,
                            message: "Please input your confirm password!",
                          },
                        ]}
                      >
                        <Input.Password
                          size="large"
                          placeholder="Enter Confirm Password"
                          className="AuthFormInput"
                          prefix={<GoKey />}
                        />
                      </Form.Item>
                     
                      <br />

                      <Form.Item>
                        <Button
                          type="primary"
                          htmlType="submit"
                          className="loginButton mainbtn"
                        >
                         UPDATE PASSWORD
                          {/* {loading ? "Loading..." : "Login"} */}
                        </Button>
                      </Form.Item>
                    </Form>


             

                    <Row style={{ width: "100%", justifyContent: "center" }}>
                      <Col xs={24} md={12}>
                       

                      <Typography.Text
                    className="fontFamily1"
                    style={{
                      fontSize: "14px",
                      color: "black",
                      textAlign: "left",
                      marginTop: 0,
                      marginBottom: 30,
                      textAlign: "center",
                      display: "block",
                    }}
                  >
                    <>
                    Back To  <span
                        onClick={() => navigate("/login")}
                        style={{
                          cursor: "pointer",
                          fontWeight: "bold",
                          textDecoration: "underline",
                          color: "#000",
                        }}
                      >
                       Login
                      </span>
                    </>
                  </Typography.Text>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </Card>
          </Col>
        </Row>
      </Layout>
      <Modal title="" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
     <Image
                  preview={false}
                  src={ImageUrl("tick.png")}
                  className="mt-15 mb-15 tickimage"
                />
        <h4>PAYMENT SUCCESS</h4>
        <p>Your payment has been made successfully!</p>
       
        <Col xs={24} className="flex mt-15"><Button className="mainbtn" onClick={() => navigate("/")}>CONTINUE</Button></Col>
      </Modal>
    </>
  )
}

export default PasswordRecovery