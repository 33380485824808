import React, { useEffect, useState } from "react";
import { Col, Image, Row, Input, Form, Checkbox, Button } from "antd";
import { IoIosArrowDropleftCircle } from "react-icons/io";
import { useLocation, useNavigate } from "react-router-dom";
import { ImageUrl } from "../../config/helper";
import OrderSummary from "../../components/orderSummary";
import {
  FaEnvelope,
  FaFileAlt,
  FaGlobeAmericas,
  FaRegUser,
} from "react-icons/fa";
import { CiMobile1 } from "react-icons/ci";
import { PiBuildingApartmentLight, PiMapPinArea } from "react-icons/pi";
import { Post } from "../../config/api/post";
import { ORDER, UPLOADS_URL } from "../../config/constants/api";
import { useSelector } from "react-redux";
import swal from "sweetalert";
import { useGetMyCartQuery } from "../../api/cart";
import ScrollTop from "../../components/ScrollTop/ScrollTop";

function CheckOut() {
  const navigate = useNavigate();
  const location = useLocation();
  const cart = location?.state?.cart;
  const token = useSelector((state) => state.user.userToken);

  const { data: cartData } = useGetMyCartQuery("")
  const cartProducts = cartData?.cart?.products ?? []

  useEffect(() => {
    if (!cart) {
      navigate(-1);
    }
  }, []);

  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };

  const [isChecked, setIsChecked] = useState(false);
  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };

  const onFinish = async (values) => {
    let data = {
      firstName: values?.fname,
      lastName: values?.lname,
      email: values?.email,
      phone: values?.number,
      shippingDetails: {
        firstName: values?.shippingfname,
        lastName: values?.shippinglname,
        number: values?.shippingnumber,
        country: values?.shippingcountry,
        state: values?.shippingstate,
        city: values?.shippingcity,
        zipCode: values?.shippingzip,
        address: values?.residential,
      },
    };
    Post(ORDER.placeOrder, data, token)
      .then((response) => {
        if (response?.success) {
          swal("System Alert", response?.message, "success");
          navigate(`/payment-details/${response?.order?._id}`);
          // window.location.reload();
        }
      })
      .catch((err) => {
        let message = err?.response?.data?.message || err?.message;
        console.log(":::;", err);
        if (message) swal("Oops!", message, "error");
      });
  };
  return (
    <div className="innerpage">
      <Row justify={"center"}>
        <Col xs={22} md={20}>
          <h5>
            <IoIosArrowDropleftCircle onClick={() => navigate("/cart")} />
            Checkout
          </h5>
          
        </Col>
      </Row>

      <Row justify={"center"}>
        <Col xs={22} md={20}>
          <h5>Contact Information</h5>
          <div className="checkout-form">
            <Form layout="vertical" name="basic" onFinish={onFinish}>
              <Row gutter={20}>
                <Col xs={24} md={12} lg={12}>
                  <Form.Item
                    label="Enter First Name"
                    name="fname"
                    rules={[
                      {
                        type: "text",
                        message: "Please Enter First Name",
                        // warningOnly: true,
                      },
                      {
                        required: true,
                        message: "Please Enter First Name!",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      placeholder="Enter First Name"
                      prefix={<FaRegUser />}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12} lg={12}>
                  <Form.Item
                    label="Enter Last Name"
                    name="lname"
                    rules={[
                      {
                        type: "text",
                        message: "Please Enter Last Name",
                        // warningOnly: true,
                      },
                      {
                        required: true,
                        message: "Please Enter Last Name!",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      placeholder="Enter Last Name"
                      prefix={<FaRegUser />}
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} md={12} lg={12}>
                  <Form.Item
                    label="Enter Email Address"
                    name="email"
                    rules={[
                      {
                        type: "email",
                        message: "Please Enter Email Address",
                        // warningOnly: true,
                      },
                      {
                        required: true,
                        message: "Please Enter Email Address!",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      placeholder="Enter Email Address"
                      prefix={<FaEnvelope />}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12} lg={12}>
                  <Form.Item
                    label="Enter Number"
                    name="number"
                    rules={[
                      // {
                      //   type: "number",
                      //   message: "Please Number",
                      //   // warningOnly: true,
                      // },
                      {
                        required: true,
                        message: "Please Enter Valid Number!",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      placeholder="Enter Number"
                      prefix={<CiMobile1 />}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col xs={24}>
                  <hr />
                </Col>
              </Row>
              {/* <Row gutter={20}>
                <Col xs={22}>
                  <h5>SHIPPING ADDRESS</h5>
                </Col>

                <Col xs={24} md={12} lg={12}>
                  <Form.Item
                    label="Enter First Name"
                    name="shippingfname"
                    rules={[
                      {
                        type: "text",
                        message: "Please Enter First Name",
                        // warningOnly: true,
                      },
                      {
                        required: true,
                        message: "Please Enter First Name!",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      placeholder="Enter First Name"
                      prefix={<FaRegUser />}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12} lg={12}>
                  <Form.Item
                    label="Enter Last Name"
                    name="shippinglname"
                    rules={[
                      {
                        type: "text",
                        message: "Please Enter Last Name",
                        // warningOnly: true,
                      },
                      {
                        required: true,
                        message: "Please Enter Last Name!",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      placeholder="Enter Last Name"
                      prefix={<FaRegUser />}
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} md={12} lg={12}>
                  <Form.Item
                    label="Enter Number"
                    name="shippingnumber"
                    rules={[
                      // {
                      //   type: "number",
                      //   message: "Please Number",
                      //   // warningOnly: true,
                      // },
                      {
                        required: true,
                        message: "Please Enter Valid Number!",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      placeholder="Enter Number"
                      prefix={<CiMobile1 />}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12} lg={12}>
                  <Form.Item
                    label="Enter Residential Address"
                    name="residential"
                    rules={[
                      {
                        type: "text",
                        message: "Please Enter Residential Address",
                        // warningOnly: true,
                      },
                      {
                        required: true,
                        message: "Please Enter Residential Address!",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      placeholder="Enter Residential Address"
                      prefix={<PiMapPinArea />}
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} md={12} lg={12}>
                  <Form.Item
                    label="Country"
                    name="shippingcountry"
                    rules={[
                      {
                        message: "Please Select Country!",
                        // warningOnly: true,
                      },
                      {
                        required: true,
                        message: "Please Select Country!",
                      },
                    ]}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: "4px 11px",
                        background: "#fff",
                        height: "45px",
                      }}
                    >
                      <FaGlobeAmericas
                        style={{ marginRight: "8px", color: "#FD0100" }}
                      />
                      <select
                        style={{ flex: 1 }}
                        placeholder="Select an option"
                      >
                        <option value="option1">option 1</option>
                        <option value="option2">option 2</option>
                        <option value="option3">option 3</option>
                      </select>
                    </div>
                  </Form.Item>
                </Col>
                <Col xs={24} md={12} lg={12}>
                  <Form.Item
                    label="State"
                    name="shippingstate"
                    rules={[
                      {
                        message: "Please Select State!",
                        // warningOnly: true,
                      },
                      {
                        required: true,
                        message: "Please Select State!",
                      },
                    ]}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: "4px 11px",
                        background: "#fff",
                        height: "45px",
                      }}
                    >
                      <PiBuildingApartmentLight
                        style={{ marginRight: "8px", color: "#FD0100" }}
                      />
                      <select
                        style={{ flex: 1 }}
                        placeholder="Select an option"
                      >
                        <option value="option1">option 1</option>
                        <option value="option2">option 2</option>
                        <option value="option3">option 3</option>
                      </select>
                    </div>
                  </Form.Item>
                </Col>
                <Col xs={24} md={12} lg={12}>
                  <Form.Item
                    label="City"
                    name="shippingcity"
                    rules={[
                      {
                        message: "Please Select City!",
                        // warningOnly: true,
                      },
                      {
                        required: true,
                        message: "Please Select City!",
                      },
                    ]}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: "4px 11px",
                        background: "#fff",
                        height: "45px",
                      }}
                    >
                      <PiBuildingApartmentLight
                        style={{ marginRight: "8px", color: "#FD0100" }}
                      />
                      <select
                        style={{ flex: 1 }}
                        placeholder="Select an option"
                      >
                        <option value="option1">option 1</option>
                        <option value="option2">option 2</option>
                        <option value="option3">option 3</option>
                      </select>
                    </div>
                  </Form.Item>
                </Col>

                <Col xs={24} md={12} lg={12}>
                  <Form.Item
                    label="Zip Code"
                    name="shippingzip"
                    rules={[
                      // {
                      //   type: "number",
                      //   message: "Please Zip Code",
                      //   // warningOnly: true,
                      // },
                      {
                        required: true,
                        message: "Please Enter Zip Code!",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      placeholder="Zip Code"
                      prefix={<FaFileAlt />}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Checkbox onChange={handleCheckboxChange}>
                Is Billing Address same as Shipping Address?
              </Checkbox> */}

              {isChecked && (
                <div>
                  <Row>
                    <Col xs={24}>
                      <hr />
                    </Col>
                  </Row>
                  <Row gutter={20}>
                    <Col xs={22}>
                      <h5>BILLING ADDRESS</h5>
                    </Col>

                    <Col xs={24} md={12} lg={12}>
                      <Form.Item
                        label="Enter First Name"
                        name="fname"
                        rules={[
                          {
                            type: "text",
                            message: "Please Enter First Name",
                            // warningOnly: true,
                          },
                          {
                            required: true,
                            message: "Please Enter First Name!",
                          },
                        ]}
                      >
                        <Input
                          size="large"
                          placeholder="Enter First Name"
                          prefix={<FaRegUser />}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={12} lg={12}>
                      <Form.Item
                        label="Enter Last Name"
                        name="lname"
                        rules={[
                          {
                            type: "text",
                            message: "Please Enter Last Name",
                            // warningOnly: true,
                          },
                          {
                            required: true,
                            message: "Please Enter Last Name!",
                          },
                        ]}
                      >
                        <Input
                          size="large"
                          placeholder="Enter Last Name"
                          prefix={<FaRegUser />}
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={24} md={12} lg={12}>
                      <Form.Item
                        label="Enter Number"
                        name="number"
                        rules={[
                          // {
                          //   type: "number",
                          //   message: "Please Number",
                          //   // warningOnly: true,
                          // },
                          {
                            required: true,
                            message: "Please Enter Valid Number!",
                          },
                        ]}
                      >
                        <Input
                          size="large"
                          placeholder="Enter Number"
                          prefix={<CiMobile1 />}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={12} lg={12}>
                      <Form.Item
                        label="Enter Residential Address"
                        name="Residential"
                        rules={[
                          {
                            type: "text",
                            message: "Please Enter Residential Address",
                            // warningOnly: true,
                          },
                          {
                            required: true,
                            message: "Please Enter Residential Address!",
                          },
                        ]}
                      >
                        <Input
                          size="large"
                          placeholder="Enter Residential Address"
                          prefix={<PiMapPinArea />}
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={24} md={12} lg={12}>
                      <Form.Item
                        label="Country"
                        name="country"
                        rules={[
                          {
                            message: "Please Select Country!",
                            // warningOnly: true,
                          },
                          {
                            required: true,
                            message: "Please Select Country!",
                          },
                        ]}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            padding: "4px 11px",
                            background: "#fff",
                            height: "45px",
                          }}
                        >
                          <FaGlobeAmericas
                            style={{ marginRight: "8px", color: "#FD0100" }}
                          />
                          <select
                            style={{ flex: 1 }}
                            placeholder="Select an option"
                          >
                            <option value="option1">option 1</option>
                            <option value="option2">option 2</option>
                            <option value="option3">option 3</option>
                          </select>
                        </div>
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={12} lg={12}>
                      <Form.Item
                        label="State"
                        name="State"
                        rules={[
                          {
                            message: "Please Select State!",
                            // warningOnly: true,
                          },
                          {
                            required: true,
                            message: "Please Select State!",
                          },
                        ]}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            padding: "4px 11px",
                            background: "#fff",
                            height: "45px",
                          }}
                        >
                          <PiBuildingApartmentLight
                            style={{ marginRight: "8px", color: "#FD0100" }}
                          />
                          <select
                            style={{ flex: 1 }}
                            placeholder="Select an option"
                          >
                            <option value="option1">option 1</option>
                            <option value="option2">option 2</option>
                            <option value="option3">option 3</option>
                          </select>
                        </div>
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={12} lg={12}>
                      <Form.Item
                        label="City"
                        name="City"
                        rules={[
                          {
                            message: "Please Select City!",
                            // warningOnly: true,
                          },
                          {
                            required: true,
                            message: "Please Select City!",
                          },
                        ]}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            padding: "4px 11px",
                            background: "#fff",
                            height: "45px",
                          }}
                        >
                          <PiBuildingApartmentLight
                            style={{ marginRight: "8px", color: "#FD0100" }}
                          />
                          <select
                            style={{ flex: 1 }}
                            placeholder="Select an option"
                          >
                            <option value="option1">option 1</option>
                            <option value="option2">option 2</option>
                            <option value="option3">option 3</option>
                          </select>
                        </div>
                      </Form.Item>
                    </Col>

                    <Col xs={24} md={12} lg={12}>
                      <Form.Item
                        label="Zip Code"
                        name="number"
                        rules={[
                          // {
                          //   type: "number",
                          //   message: "Please Zip Code",
                          //   // warningOnly: true,
                          // },
                          {
                            required: true,
                            message: "Please Enter Zip Code!",
                          },
                        ]}
                      >
                        <Input
                          size="large"
                          placeholder="Zip Code"
                          prefix={<FaFileAlt />}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              )}

              <Col xs={24} className="flex mt-15">
                <Button
                  className="mainbtn"
                  htmlType="submit"
                  // onClick={() => navigate("/payment-details", { state: { cart } })}
                // onClick={handlePlaceOrder}
                >
                  PLACE ORDER
                </Button>
              </Col>
            
            </Form>
          </div>
        </Col>
      </Row>

      <div className="about-section product-details">
            <Row gutter={20} justify={"space-around"}>
              <Col xs={24} md={8} lg={6} className="text-center">
                <Image
                  preview={false}
                  src={UPLOADS_URL + cartProducts[0]?.product?.image}
                  className="mb-15"
                />
                <span className="card-title text-center">
                  {cartProducts[0]?.product?.title}
                </span>
                <span className="card-price text-center">$ {Number(cartProducts[0]?.product?.price)?.toFixed(2)}</span>
              </Col>
              <Col xs={24} md={16} lg={12}>
                <OrderSummary subTotal={cart?.totalPrice} />
              </Col>
            </Row>
          </div>
    </div>
  );
}

export default CheckOut;
