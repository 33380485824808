import React, { useState } from "react";
import AliceCarousel from "react-alice-carousel";
import CardFrame from "../card";
import { useQuery } from "react-query";
import { Get } from "../../config/api/get";
import { PRODUCTS } from "../../config/constants/api";

const responsive = {
  380: { items: 1 },
  568: { items: 1 },
  1024: { items: 2 },
  1200: { items: 3 },
  1400: { items: 3 },
  1500: { items: 4 },
};

function CarouselSlider({products}) {
  // const getProducts = async () => {
  //   const response = await Get(PRODUCTS.getAllProducts);
  //   if (response?.success) {
  //     return response?.products;
  //   } else {
  //     throw new Error("Error fetching user");
  //   }
  // };
  // const { data, error, isLoading } = useQuery(
  //   "getProducts", // Unique query key based on the user ID
  //   getProducts,
  //   {
  //     retry: 1, // Optional: Retry once if the query fails
  //     onError: (err) => console.log(err),
  //   }
  // );
  // console.log(data, "data");
  const items =
    Array.isArray(products) &&  products.length > 0 &&
    products?.map((item, index) => {
      return (
        <div className="item" key={index}>
          <CardFrame item={item} products={products}/>
        </div>
      );
    });

  return (
    <div className="categeorySlider">
      <AliceCarousel
        mouseTracking
        items={items}
        responsive={responsive}
        disableButtonsControls
        disableDotsControls
        controlsStrategy="alternate"
        paddingLeft={0}
        paddingRight={0}
        infinite
        autoPlay
      />
    </div>
  );
}

export default CarouselSlider;
