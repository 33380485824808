import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BASE_URL, CART, ORDER } from '../config/constants/api';

const prepareHeaders = (headers, { getState }) => {
  try {
    const token = getState()?.user?.userToken;
    
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
  
    return headers;
  } catch (error) {
    console.log(error)
  }
};

export const cartApi = createApi({
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL, prepareHeaders }),
  tagTypes: ['Cart'],
  endpoints: (builder) => ({
    getMyCart: builder.query({
      query: (name) => CART.getMyCart + name,
      forceRefetch({ currentArg, previousArg }) {
        return true
        return currentArg !== previousArg
      },
    }),
    getOrderById: builder.query({
      query: (id) => '/order/' + id,
      forceRefetch({ currentArg, previousArg }) {
        return true
        return currentArg !== previousArg
      },
    }),
    getClientId: builder.query({
      query: () => '/paypal',
      forceRefetch({ currentArg, previousArg }) {
        return true
        return currentArg !== previousArg
      },
    }),
    getCoins: builder.query({
      query: () => '/coins',
      forceRefetch({ currentArg, previousArg }) {
        return true
        return currentArg !== previousArg
      },
    }),
  }),
})

export const { useGetMyCartQuery, useGetOrderByIdQuery, useGetClientIdQuery, useGetCoinsQuery } = cartApi
// export const { useGetMyCartQuery, useGetOrderByIdQuery, useGetClientIdQuery } = cartApi
