import { Button, Col, Form, Image, Input, Row } from "antd";
import React from "react";
import { IoIosArrowDropleftCircle } from "react-icons/io";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useGetCoinsQuery } from "../../api/cart";
import { ImageUrl } from "../../config/helper";
import { Post } from "../../config/api/post";
import swal from "sweetalert";

function RedeemCoins() {
  const { refetch: refetchCoins } = useGetCoinsQuery();
  const navigate = useNavigate();
  // get state from route
  const { pathname } = useLocation();
  const coinType = pathname.includes('gold') ? 'gold' : pathname.includes('red') ? 'red' : null;

  const [form] = Form.useForm();

  const token = useSelector((state) => state.user.userToken);

  const submit = (values) => {
    console.log("values", values);
    let data = {
      type: coinType,
      amount: +values.amount,
      note: values.note
    };
    Post('/coins/redeem', data, token)
      .then((response) => {
        if (response?.status) {
          refetchCoins();
          form.resetFields();
          swal("System Alert", response?.message, "success");
        }
      })
      .catch((err) => {
        let message = err?.response?.data?.message || err?.message;
        console.log(":::;", err);
        if (message) swal("Oops!", message, "error");
      });
  };

  return (
    <div className="innerpage">
      <Row justify={"center"}>
        <Col xs={22} md={20}>
          <h5 className={`${coinType === "red" ? "stroke" : null}`}>
            <IoIosArrowDropleftCircle onClick={() => navigate("/checkout")} />
            <span>
              {`${coinType === 'red' ? 'REDEEM' : 'SEND'} ${coinType} coins`.toUpperCase()}
              </span>
          </h5>

          <div className="about-section product-details">
            <Row gutter={20} justify={'between'}>
              <Col xs={24} md={14} lg={12}>
                <span className={`card-title ${coinType === "red" ? "stroke" : null}`}>{`Submit request to ${coinType === 'red' ? 'redeem' : 'send'} ${coinType} coins`}</span>
                <br />
                <Form
                  name="basic"
                  onFinish={submit}
                  form={form}
                >
                  <Form.Item
                    name="amount"
                    rules={[{ required: true, message: 'Please input amount!' }]}
                  >
                    <Input placeholder="Amount" />
                  </Form.Item>
                  <Form.Item
                    name="note"
                    rules={[{ required: false }]}
                  >
                    <Input.TextArea placeholder="Notes" />
                  </Form.Item>
                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      {`${coinType === "red" ? "Redeem" : "Send"} coins`}
                    </Button>
                  </Form.Item>
                </Form>

              </Col>
              <Col xs={24} md={10} lg={12}>
                <Image
                  preview={false}
                  src={ImageUrl("redeem-coin.jpg")}
                  className="mb-15"
                  alt="card"
                  height={500}
                  width={500}
                />
              </Col>

            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default RedeemCoins;
