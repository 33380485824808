import React from 'react'
import { Row, Col, Image, Button} from "antd";
import { ImageUrl } from "../../config/helper";
import { useNavigate,Link } from "react-router-dom";
import useMediaQuery from '../../hooks/useMediaQuery';
import styles from './AboutSection.module.css'

function ReduceText(text, limit) {
  return text.length > limit ? text.slice(0, limit) + "..." : text;
};

const aboutText = `Real Money Dragon is number 1 online gaming network and offers a legit gaming entertainment for game enthusiasts. Over the years, we have built a reputation that speaks itself, and proudly serving over a million players. Real Money Dragon platform is a forward-thinking online gaming platform for gaming, particularly through the sale of digital dragon-based cards and this approach sets us apart from traditional online gaming platforms. Now, we are taking a step ahead and digitalizing our offering to make the gaming experience more enthralling and exciting for our players. We aim to become the most favorite gaming platform of the nation and committed to provide our players an exceptional gaming experience. It’s time to play beyond the limits!`

function AboutSection() {
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width: 767px)")
  return (
    <div className='about-section'> 
        <Row gutter={20}>
            <Col xs={24} md={10} lg={8}>
                <div className="wow fadeInUp" data-wow-duration="2.5s" data-wow-delay="0.8s">
                <Image
              preview={false}
              src={ImageUrl("about-image1.png")}
              alt="card"
              
            />
                </div>
            
            </Col>
            <Col xs={24} md={14} lg={16}>
              <h3>About Us</h3>
            <p className={styles.aboutPara}>{isMobile ? ReduceText(aboutText, 400) : aboutText}</p>
            <Button className="mainbtn"  onClick={() => navigate("/about-us")}>Read More</Button>
            </Col>
        </Row>
        
    </div>
  )
}

export default AboutSection