import React, { useEffect, useState } from "react";
import { Row, Col, Input, Button, Pagination } from "antd";
import { ImageUrl } from "../../config/helper";
import CardFrame from "../../components/card";
import { CiSearch } from "react-icons/ci";
import { useQuery } from "react-query";
import { Get } from "../../config/api/get";
import { PRODUCTS } from "../../config/constants/api";
import { useDebouncedCallback } from "use-debounce";

const { Search } = Input;
function Shop({ heading, title }) {
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState(null);
  const [paginationConfig, setPaginationConfig] = useState({
    pageNumber: 1,
    limit: 10,
    totalDocs: 0,
    totalPages: 0,
  });
  const getProducts = (pageNumber, pageSize, keyword) => {
    setLoading(true);
    Get(PRODUCTS.getAllProducts, null , {
      page: pageNumber
        ? pageNumber.toString()
        : paginationConfig.pageNumber.toString(),
      limit: pageSize ? pageSize.toString() : paginationConfig.limit.toString(),
      keyword,
    })
      .then((response) => {
        if (response?.status) {
          setProducts(response?.data?.docs);
          setPaginationConfig({
            pageNumber: response?.data?.page,
            limit: response?.data?.limit,
            totalDocs: response?.data?.totalDocs,
            totalPages: response?.data?.totalPages,
          });
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log("Error fetching blogs ", err);
        setLoading(false);
      });
  };
  useEffect(() => {
    getProducts();
  }, []);
  const handleSearch = useDebouncedCallback((e) => {
    console.log(e.target.value);
    getProducts(
      paginationConfig.pageNumber,
      paginationConfig.limit,
      e.target.value
    );
  }, 1000);
  const startIndex =
    (paginationConfig.pageNumber - 1) * paginationConfig.limit + 1;
  const endIndex = Math.min(
    startIndex + paginationConfig.limit - 1,
    paginationConfig.totalDocs
  );
  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }
    return originalElement;
  };
  const message = products
    ? `Showing records ${endIndex} of ${paginationConfig.totalDocs}`
    : "Showing records 0 of 0";
  const handlePageChange = (pageNumber) => {
    setPaginationConfig({
      ...paginationConfig,
      pageNumber: pageNumber,
    });
    getProducts(pageNumber, paginationConfig.limit);
  };

  const { Search } = Input;
  const onSearch = (value, _e, info) => console.log(info?.source, value);
  return (
    <div className="innerpage">
      <Row justify={"center"}>
        <Col xs={22} md={22}>
          <h5>Shop CARDS</h5>
          <h6>You'll find it here!</h6>
        </Col>
        <Col xs={22} md={20}>
          <h4 className="text-center">{title}</h4>
          <h3 className="text-center">{heading}</h3>

          <div className="searcbar">
            <Button type="primary" icon={<CiSearch />} />
            <Input
              placeholder="Search Product Here......."
              style={{ marginLeft: "10px", flexGrow: 1 }}
              onChange={handleSearch}
            />
          </div>

          <Row justify={"center"} gutter={20} style={{ marginBottom: "50px" }}>
            {Array.isArray(products) &&
              products.length &&
              products.map((item, index) => {
                return (
                  <Col xs={22} md={12} lg={8}>
                    <CardFrame
                      item={item}
                      products={products}
                      // cardText={item.cardText}
                      // cardAmount={item.cardAmount}
                    />
                  </Col>
                );
              })}
          </Row>
          <Row style={{ padding: "10px 20px" }}>
            <Col xs={24} md={12}>
              <p style={{ color: "white" }}>{message}</p>
            </Col>
            <Col
              xs={24}
              md={12}
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Pagination
                className="styledPagination"
                onChange={(e) => handlePageChange(e)}
                current={parseInt(paginationConfig?.pageNumber)}
                pageSize={paginationConfig?.limit}
                total={paginationConfig?.totalDocs}
                itemRender={itemRender}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default Shop;
