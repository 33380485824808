import React from 'react'
import { Row, Col, Image, Button } from "antd";
import { ImageUrl } from "../../config/helper";
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

function AudioPlayerComp() {
  return (
    <div className='audioplayer'>
        <Row gutter={20} align={"middle"}>
            <Col xs={24} sm={8} md={10} lg={6}>
                    <Image
                preview={false}
                src={ImageUrl("audio-player-profile.png")}
            />
            </Col>
            <Col xs={24} sm={16} md={14} lg={18}>
                <h5>How to avoid making bad choices</h5>
                <h6>Episode 01 . Technology . 12.13 min</h6>
                        <AudioPlayer
                autoPlay
                src="http://example.com/audio.mp3"
                onPlay={e => console.log("onPlay")}
                // other props here
  />
            </Col>
        </Row>
    </div>
  )
}

export default AudioPlayerComp