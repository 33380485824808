import React from 'react'
import { Row, Col, Image } from "antd";
import { ImageUrl } from "../../config/helper";
import Post from '../../components/post';
import PostHead from '../../components/postHead';

function Community() {
  return (
    <div className="innerpage">
    <Row justify={"center"}>
              
                <Col  xs={22} md={20} >
                    <h5 className="mt-50">Community</h5>
                </Col>

                <Col  xs={22} md={20} >
                    <div className='golden-border'>
                    <PostHead/>
                     <Post/>
                     <Post/>
                    </div>
                    
                </Col>
                
               
               
            </Row>
    </div>
  )
}

export default Community