import React, { useState }  from 'react'
import { Row, Col, Input, Button  } from "antd";
import { ImageUrl } from "../../config/helper";
import CoinsCard from '../../components/coinsCard';

function Coins() {
    const [coinCards, SetCoinCards] = useState([
        {
          key: 1,
          image: "coins-1.png",
          heading: "Standard PACKAGE",
          coins:"10,000",
          price:"100.00"
        },
    
        {
          key: 2,
          image: "coins-2.png",
          heading: "Standard PACKAGE",
          coins:"20,000",
          price:"150.00"
        },
        {
          key: 3,
          image: "coins-3.png",
          heading: "Standard PACKAGE",
          coins:"35,000",
          price:"200.00"
        },
      ]);
  return (
    <div  className='innerpage'>
        <Row justify={"center"}>
            <Col xs={22} md={22} lg={20}>
                <h5>My Buxx Coins</h5>
                <h6>Absolutely. Positively. Perfect</h6>
                <Row gutter={20} justify={"center"}>
                    
                    {coinCards.length > 0 &&
                coinCards.map((item) => {
                  return (
                    <Col xs={22} md={12} lg={8}>
                      <CoinsCard item={item}/>
                    </Col>
                  );
                })}
                        
                  
                </Row>
                
            
            </Col>
        </Row>
    </div>
  )
}

export default Coins