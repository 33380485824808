import React from 'react'
import { Row, Col, Image, Avatar, Input,  Button, Upload  } from 'antd'
import {FcAddImage} from 'react-icons/fc';
import { ImageUrl } from "../../config/helper";

function PostHead() {
    const props = {
        action: '//jsonplaceholder.typicode.com/posts/',
        listType: 'picture',
        previewFile(file) {
          console.log('Your upload file:', file);
          // Your process logic. Here we just mock to the same file
          return fetch('https://next.json-generator.com/api/json/get/4ytyBoLK8', {
            method: 'POST',
            body: file,
          })
            .then((res) => res.json())
            .then(({ thumbnail }) => thumbnail);
        },
      };
  return (
    <Row justify="center"  className='info-area py-25 greybg'>
            
            <Col xs={24}>
              
                <Row gutter={20}>
                    <Col xs={5} md={4} lg={2} xl={1}> <Avatar size={32} icon={<Image preview={false} src={ImageUrl("avatar-profile.png")} />} /></Col>
                    <Col xs={19} md={20} lg={22} xl={23}>
                    <Input placeholder="Abc" />
                    
                    </Col>
                </Row>
                <hr  className='my-25' />
                <Row justify={"space-around"}>
                <Col xs={12} className='flex felx-start'>
                <Upload {...props}>
    <Button className='uploadbtn' icon={<FcAddImage size={24} />}>Image Upto (5MB)</Button>
  </Upload>
  <Upload {...props}>
    <Button className='uploadbtn' icon={<FcAddImage size={24} />}>Image Upto (5MB)</Button>
  </Upload>
                    </Col>
                    <Col xs={12} className='flex felx-end'>
                    <Button className="mainbtn px-40">Post</Button> 
                    </Col>
                </Row>
            </Col>
            </Row>
  )
}

export default PostHead