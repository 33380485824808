import React from "react";
import { Row, Col, Image, Button } from "antd";
import { useNavigate,Link } from "react-router-dom";
import { FaFacebookF, FaInstagram, FaTiktok, FaDiscord } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

function Footer() {
  const navigate = useNavigate();
  return (
    <div className="footer z-index-99">
      <Row justify={"center"}>
        <Col xs={22} md={20}>
          <Row>
            <Col xs={24} md={24} lg={10}>
              <p>Real Money Dragon &copy; 2024 - All Right Reserved</p>
            </Col>
            <Col xs={24} md={24} lg={14}>
              <Row>
                <Col xs={24} md={12} lg={12}>
                  <FaFacebookF />
                  <FaInstagram />
                  <FaDiscord />
                  <FaXTwitter />
                  <FaTiktok />
                </Col>
                <Col xs={24} md={12} lg={12}>
                  <ul>
                    <li onClick={() => navigate("/privacy-terms")}><Link >Privacy Policy</Link></li>
                    <li onClick={() => navigate("/privacy-terms")}><Link >Terms & Conditions</Link></li>
                  </ul>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default Footer;
