import React, { useState } from "react";
import { Row, Col, Image } from "antd";
import { ImageUrl } from "../../config/helper";

function WinnerList() {
  const [winnerList, SetWinnerList] = useState([
    {
      key: 1,
      image: "deep.png",
      heading: "Deep",
      coins: " 12,300",
    },

    {
      key: 2,
      image: "deep.png",
      heading: "Deep",
      coins: " 12,300",
    },
    {
      key: 3,
      image: "deep.png",
      heading: "Deep",
      coins: " 12,300",
    },
    {
      key: 4,
      image: "deep.png",
      heading: "Deep",
      coins: " 12,300",
    },

    {
      key: 5,
      image: "deep.png",
      heading: "Deep",
      coins: " 12,300",
    },
    {
      key: 6,
      image: "deep.png",
      heading: "Deep",
      coins: " 12,300",
    },
    {
      key: 7,
      image: "deep.png",
      heading: "Deep",
      coins: " 12,300",
    },

    {
      key: 8,
      image: "deep.png",
      heading: "Deep",
      coins: " 12,300",
    },
    {
      key: 9,
      image: "deep.png",
      heading: "Deep",
      coins: " 12,300",
    },
    {
      key: 10,
      image: "deep.png",
      heading: "Deep",
      coins: " 12,300",
    },

    {
      key: 11,
      image: "deep.png",
      heading: "Deep",
      coins: " 12,300",
    },
    {
      key: 12,
      image: "deep.png",
      heading: "Deep",
      coins: " 12,300",
    },
  ]);

  return (
    <div>
      <div className="position-relative">
        <Image
          preview={false}
          src={ImageUrl("dragon-bg.png")}
          className="bg-vid"
        />
      </div>
      <div className="innerpage">
        <Row justify={"center"}>
          <Col xs={22} md={20}>
            <h5 className="mt-50">WINNER LIST</h5>
          </Col>
          <Col xs={22} md={20}>
            <Row gutter={40} justify={"center"}>
              {winnerList.length > 0 &&
                winnerList.map((item) => {
                  return (
                    <Col xs={24} sm={12} md={8} lg={6}>
                      <WinnerListCard {...item}  />
                    </Col>
                  );
                })}
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default WinnerList;

export function WinnerListCard({image, heading, coins}) {
  return (
    <div className="winner-list-card">
      <Image preview={false} src={ImageUrl(image)} />
      <h3>{heading}</h3>
      <p>
        <Image preview={false} src={ImageUrl("dollar-img.png")} /> {coins} Coins
      </p>
    </div>
  );
}
