import React from 'react'
import { Image, Button} from "antd";
import { ImageUrl } from "../../config/helper";

function CoinsCard({item}) {
  return (
    <div className='coins-card'>
        <div className='coins-card-top'>
            <Image  preview={false}   src={ImageUrl(item.image)} />
            <h4>{item.heading}</h4>
            <h3><sup>$</sup>{item.price}</h3>
        </div>
        <div className='coins-card-bottom'>
            <h5><Image  preview={false}  src={ImageUrl("coins.png")}  />{item.coins} Coins</h5>
            <div  className='flex mt-15'>
            <Button className='mainbtn flex'>SELECT PACKAGE</Button>
            </div>
            
        </div>
    </div>
  )
}

export default CoinsCard